import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=4b0818e7&scoped=true"
import script from "./Counter.vue?vue&type=script&lang=ts"
export * from "./Counter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0818e7",
  null
  
)

export default component.exports