<!--
  #%L
  Fishola :: Mobile
  %%
  Copyright (C) 2019 - 2021 INRAE - UMR CARRTEL
  %%
  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.
  
  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.
  
  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see <http://www.gnu.org/licenses/>.
  #L%
  -->
<template>
  <div class="footer keyboardSensitive hide-on-desktop">
    <FooterButton
      class="keyboardSensitive"
      v-if="!hideButton && (buttonIcon || buttonText)"
      v-bind:icon="buttonIcon"
      v-bind:text="buttonText"
      v-bind:deleteMode="buttonIcon == 'icon-delete'"
      :isWaitingForPositionBeforeGoingToNextPage="
        isWaitingForPositionBeforeGoingToNextPage
      "
      v-on:clicked="$emit('buttonClicked')"
    />

    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['logout']"
      v-on:click="logout"
    >
      <i class="icon-logout"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['back']"
      v-on:click="goBack"
    >
      <i class="icon-arrow icon-back"></i>
    </div>
    <div
      class="footer-element steps hiddenWhenKeyboardShows"
      v-if="steps.length > 0"
    >
      <div
        v-for="s in steps"
        v-bind:key="s.id"
        v-bind:class="s.active ? 'step step-active' : 'step'"
      >
        <!-- {{s.active}} -->
      </div>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['spacer']"
    >
      <!-- spacer -->
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'dashboard' ? 'filled' : 'unfilled'"
      v-if="activeButtons['dashboard']"
      v-on:click="goDashboard"
    >
      <i class="icon-dashboard"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'settings' ? 'filled' : 'unfilled'"
      v-if="activeButtons['settings']"
      v-on:click="goSettings"
    >
      <i class="icon-settings"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'credits' ? 'filled' : 'unfilled'"
      v-if="activeButtons['credits']"
      v-on:click="goCredits"
    >
      <i class="icon-info"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'home' ? 'filled' : 'unfilled'"
      v-if="activeButtons['home']"
      v-on:click="goHome"
    >
      <i class="icon-home"></i>
    </div>
    <div class="footer-element timer hiddenWhenKeyboardShows" v-if="timer">
      {{ timer }}
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['giveup']"
      v-on:click="giveup"
    >
      Abandon
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['delete']"
      v-on:click="doDelete"
    >
      <i class="icon-delete"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'profile' ? 'filled' : 'unfilled'"
      v-if="activeButtons['profile']"
      v-on:click="goProfile"
    >
      <i class="icon-profile"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'documentation' ? 'filled' : 'unfilled'"
      v-if="activeButtons['documentation']"
      v-on:click="goDocumentation"
    >
      <i class="icon-files"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-bind:class="selected == 'feedback' ? 'filled' : 'unfilled'"
      v-if="activeButtons['feedback']"
      v-on:click="openFeedback"
    >
      <i class="icon-faq"></i>
    </div>
    <div
      class="footer-element pastille hiddenWhenKeyboardShows"
      v-if="activeButtons['blank']"
    >
      <!-- spacer -->
    </div>
  </div>
</template>

<script lang="ts">
import TripsService from "@/services/TripsService";
import ProfileService from "@/services/ProfileService";

import Helpers from "@/services/Helpers";

import router from "@/router";
import { RouterUtils } from "@/router/RouterUtils";
import FooterButton from "@/components/layout/FooterButton.vue";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    FooterButton,
  },
})
export default class FisholaFooter extends Vue {
  @Prop({ default: "logout,dashboard,home" }) shortcuts!: string;
  @Prop() selected?: string;
  @Prop() buttonIcon?: string;
  @Prop() buttonText?: string;
  @Prop() backEvent?: string;
  @Prop({ default: false }) hideButton: boolean;
  @Prop({ default: false }) isWaitingForPositionBeforeGoingToNextPage: boolean;

  steps: any[] = [];
  timer: string = "";

  activeButtons: any = {
    back: false,
    logout: false,
    spacer: false,
    dashboard: false,
    home: false,
    giveup: false,
    delete: false,
    blank: false,
    credits: false,
    documentation: false,
    settings: false,
    profile: false,
    feedback: false,
  };

  mounted() {
    const array = this.shortcuts.split(",");
    array.forEach(this.activeButton);
  }

  activeButton(key: string) {
    if (key && key.indexOf("step-") == 0) {
      const stepIndex = parseInt(key[5]);
      const stepCount = parseInt(key[7]);

      for (let i = 1; i <= stepCount; i++) {
        this.steps.push({
          id: i,
          active: i <= stepIndex,
        });
      }
    }
    if (key && key.indexOf("timer-") == 0) {
      const seconds: number = parseInt(key.substring(6));
      this.timer = Helpers.formatSecondsDurationTruncate(seconds);
    }
    this.activeButtons[key] = true;
  }

  logout() {
    Helpers.confirm(
      this.$modal,
      "Voulez-vous vous déconnecter ?",
      "Déconnexion"
    ).then(() => {
      this.logoutConfirmed(false);
    });
  }

  promptLogoutWithRunningTrip() {
    Helpers.confirm(
      this.$modal,
      "Vous avez une sortie en cours, elle sera perdue. Êtes-vous sûr\u00B7e ?",
      "Déconnexion"
    ).then(() => {
      this.logoutConfirmed(true);
    });
  }

  logoutConfirmed(ignoreRunningTrip: boolean) {
    if (ignoreRunningTrip) {
      ProfileService.logout().then(this.logguedOut);
    } else {
      TripsService.hasRunningTrip().then((hasRunningTrip: boolean) => {
        if (hasRunningTrip) {
          this.promptLogoutWithRunningTrip();
        } else {
          ProfileService.logout().then(this.logguedOut);
        }
      });
    }
  }

  logguedOut() {
    RouterUtils.pushRouteNoDuplicate(router, "/login");
    this.$root.$emit("loggued-out");
  }

  goBack() {
    if (this.backEvent) {
      this.$emit(this.backEvent);
    } else {
      window.history.back();
    }
  }

  goDashboard() {
    RouterUtils.pushRouteNoDuplicate(router, "/dashboard");
  }

  goCredits() {
    RouterUtils.pushRouteNoDuplicate(router, "/credits");
    this.$root.$emit("close-feedback");
  }

  goDocumentation() {
    RouterUtils.pushRouteNoDuplicate(router, "/documentation");
  }

  goHome() {
    RouterUtils.pushRouteNoDuplicate(router, "/trips");
  }

  goProfile() {
    RouterUtils.pushRouteNoDuplicate(router, "/profile");
  }

  goSettings() {
    RouterUtils.pushRouteNoDuplicate(router, "/settings");
  }

  openFeedback() {
    this.$root.$emit("open-feedback");
  }

  giveup() {
    Helpers.confirm(
      this.$modal,
      "Voulez-vous vraiment abandonner cette sortie ?"
    ).then(this.giveupConfirmed);
  }

  giveupConfirmed() {
    TripsService.cancelCreations();
    RouterUtils.pushRouteNoDuplicate(router, "/trips");
  }

  doDelete() {
    this.$emit("deleteClicked");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import "../../less/main";

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: @footer-height;
  &.keyboardShowing {
    height: @reduced-footer-height;
  }

  width: 100%;
  background-color: @zircon;
  color: @pelorous;

  .footer-element {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;

    i {
      font-size: @fontsize-footer;
    }

    &.timer {
      white-space: nowrap;
    }
  }

  .footer-element.steps {
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .step {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid @pelorous;

      margin-left: @margin-x-small;
      margin-right: @margin-x-small;
    }

    .step.step-active {
      background-color: @pelorous;
    }
  }
}
</style>
