import { render, staticRenderFns } from "./MyTripsHeader.vue?vue&type=template&id=6a6eaaf1&scoped=true"
import script from "./MyTripsHeader.vue?vue&type=script&lang=ts"
export * from "./MyTripsHeader.vue?vue&type=script&lang=ts"
import style0 from "./MyTripsHeader.vue?vue&type=style&index=0&id=6a6eaaf1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a6eaaf1",
  null
  
)

export default component.exports