var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"mobile-gallery",class:{ 'hide-on-desktop': !_vm.forceMobileMode }},[_c('div',{staticClass:"transparent-background",on:{"click":function($event){return _vm.$emit('closeModal')}}}),_c('div',{staticClass:"pane popup-content"},[_c('div',{staticClass:"no-scroll"},[_vm._t("default"),(_vm.focusedPicSrc)?_c('PictureModalMobileGallerySlider',{attrs:{"src":_vm.focusedPicSrc,"otherPics":_vm.otherPics,"readOnly":_vm.readOnly,"measurementPictureSrc":_vm.measurementPictureSrc},on:{"delete":_vm.deletePicture,"take-picture":_vm.takePicture,"seeTrip":function($event){return _vm.$emit('seeTrip')}}}):_vm._e()],2)])]),_c('div',{staticClass:"gallery",class:{ 'hide-on-mobile': !_vm.forceMobileMode, hidden: _vm.forceMobileMode }},[_c('div',{staticClass:"pic-miniatures-container"},[_vm._l((_vm.allNonMeasurePictures),function(pictureSrc){return _c('div',{key:pictureSrc.order,staticClass:"pic-miniature picture-preview",class:{
          'pic-miniature': true,
          'pic-selected': pictureSrc.content == _vm.focusedPicSrc,
        },on:{"click":function($event){_vm.focusedPicSrc = pictureSrc.content}}},[_c('img',{staticClass:"picture",attrs:{"src":pictureSrc.content,"alt":"picture"}})])}),(_vm.measurementPictureSrc)?_c('div',{key:_vm.measurementPictureSrc.order,staticClass:"pic-miniature picture-preview",class:{
          'pic-miniature': true,
          'pic-selected': _vm.measurementPictureSrc == _vm.focusedPicSrc,
        },on:{"click":function($event){_vm.focusedPicSrc = _vm.measurementPictureSrc}}},[_c('img',{staticClass:"picture",attrs:{"src":_vm.measurementPictureSrc,"alt":"picture"}})]):_vm._e(),(
          _vm.focusedPicSrc &&
          (_vm.allNonMeasurePictures.length < 4 ||
            (!_vm.measurementPictureSrc && _vm.allNonMeasurePictures.length < 5))
        )?_c('div',{staticClass:"pic-miniature picture-preview"},[_c('div',{staticClass:"picture add-pic-button"},[_c('img',{staticClass:"picture",attrs:{"src":"/img/add-pic-to-gallery.svg","alt":"Ajouter une photo"},on:{"click":_vm.takePicture}})])]):_vm._e()],2)]),_c('div',{staticClass:"picture-modal"},[_c('div',{staticClass:"pastille close",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c('i',{staticClass:"icon-plus"})]),_c('div',{staticClass:"picture-wrapper",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c('div',{staticClass:"picture-content"},[_c('img',{staticClass:"picture",class:{ hidden: _vm.forceMobileMode },attrs:{"src":_vm.focusedPicSrc,"alt":"Photo de la capture"}})])]),(_vm.allowDeletion)?_c('div',{staticClass:"replace"},[_c('button',{on:{"click":function($event){return _vm.deletePicture(_vm.focusedPicSrc)}}},[_c('i',{staticClass:"icon-delete"}),_vm._v(" Supprimer ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }