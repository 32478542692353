import { render, staticRenderFns } from "./About.vue?vue&type=template&id=820b6f72&scoped=true"
import script from "./About.vue?vue&type=script&lang=ts"
export * from "./About.vue?vue&type=script&lang=ts"
import style0 from "./About.vue?vue&type=style&index=0&id=820b6f72&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "820b6f72",
  null
  
)

export default component.exports