import { render, staticRenderFns } from "./FormInput.vue?vue&type=template&id=10bac808&scoped=true"
import script from "./FormInput.vue?vue&type=script&lang=ts"
export * from "./FormInput.vue?vue&type=script&lang=ts"
import style0 from "./FormInput.vue?vue&type=style&index=0&id=10bac808&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10bac808",
  null
  
)

export default component.exports