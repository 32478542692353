import { render, staticRenderFns } from "./OpenCVSizeComputation.vue?vue&type=template&id=e9b65ab2&scoped=true"
import script from "./OpenCVSizeComputation.vue?vue&type=script&lang=ts"
export * from "./OpenCVSizeComputation.vue?vue&type=script&lang=ts"
import style0 from "./OpenCVSizeComputation.vue?vue&type=style&index=0&id=e9b65ab2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9b65ab2",
  null
  
)

export default component.exports