import { render, staticRenderFns } from "./SomeTripSummary.vue?vue&type=template&id=4cb3a082"
import script from "./SomeTripSummary.vue?vue&type=script&lang=ts"
export * from "./SomeTripSummary.vue?vue&type=script&lang=ts"
import style0 from "./SomeTripSummary.vue?vue&type=style&index=0&id=4cb3a082&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports