import { render, staticRenderFns } from "./FormTextarea.vue?vue&type=template&id=685f8128&scoped=true"
import script from "./FormTextarea.vue?vue&type=script&lang=ts"
export * from "./FormTextarea.vue?vue&type=script&lang=ts"
import style0 from "./FormTextarea.vue?vue&type=style&index=0&id=685f8128&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685f8128",
  null
  
)

export default component.exports