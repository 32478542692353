<!--
  #%L
  Fishola :: Mobile
  %%
  Copyright (C) 2019 - 2021 INRAE - UMR CARRTEL
  %%
  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.
  
  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.
  
  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see <http://www.gnu.org/licenses/>.
  #L%
  -->

<template>
  <div class="licences-list">
    <div v-for="licence in licences" :key="licence.id">
      <FishingLicenceItem :licence="licence" @reload="$emit('reload')" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { LicenceResponseBean } from "@/pojos/BackendPojos";
import FishingLicenceItem from "./FishingLicenceItem.vue";


@Component({
  components: {
    FishingLicenceList,
    FishingLicenceItem
  },
})
export default class FishingLicenceList extends Vue {
  @Prop() licences!: LicenceResponseBean[];

}
</script>

<style scoped lang="less">
@import "../../less/main";
</style>
