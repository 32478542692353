<!--
  #%L
  Fishola :: Mobile
  %%
  Copyright (C) 2019 - 2021 INRAE - UMR CARRTEL
  %%
  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.
  
  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.
  
  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see <http://www.gnu.org/licenses/>.
  #L%
  -->
<template>
  <div class="picture-source-chooser">
    <img
      v-show="false"
      id="sourcePicture"
      style="position: absolute"
      @load="sourcePictureLoaded(0)"
      :src="measurementPictureSrc"
      alt="Photo de mesure"
    />
    <div class="transparent-background" @click="$emit('close')"></div>
    <div class="pane popup-content">
      <div class="pane-content">
        <h2 class="title">Mesure automatique</h2>
        <h4 v-if="errorMessage" class="error">{{ errorMessage }}</h4>
        <!-- pictures required for measurement -->
        <div class="picture-holder hiddenWhenKeyboardShows">
          <img id="marker" v-show="false" :src="markerSourceSRC" alt="marker"/>
          <canvas
            v-show="measurementPictureSrc && !calculating"
            id="resultCanvas"
            class="picture-display"
          />
          <img
            alt="Calcul en cours"
            v-show="calculating"
            class="picture-display"
            :src="measurementPictureSrc"
          />
        </div>
        <!-- No picture taken : display slider + camera/gallery choice -->
        <div v-if="!measurementPictureSrc">
          <div class="warning" v-if="!hasAtLeastAPicWithMarker">
            Pour utiliser cette fonctionnalité, vous devez vous
            <a :href="markerDocURL">procurer un marqueur</a>
          </div>
          <MeasurementPictureSlider />
          <div class="bottom-actions">
            <h4>Ajouter une image</h4>
            <div v-if="openCVLoaded">
              <div
                v-if="isMobilePlatform"
                class="picture-source-item first-choice"
                @click="takePictureAndTryToMeasure(false)"
              >
                <i class="pic icon-gallery" /><span>Depuis la galerie</span>
              </div>
              <div
                v-if="isMobilePlatform"
                class="picture-source-item"
                @click="takePictureAndTryToMeasure(true)"
              >
                <i class="pic icon-photo" /><span>Depuis l'appareil photo</span>
              </div>
              <div
                v-if="!isMobilePlatform"
                class="picture-source-item"
                @click="takePictureAndTryToMeasure(true)"
              >
                <i class="pic icon-photo" />Choisir une photo de mesure
              </div>
            </div>

            <div v-else>Chargement en cours...</div>
          </div>
        </div>
        <!-- Picture taken -->
        <div v-else>
          <!-- Still calculating : show taken picture & loader !-->
          <div v-if="calculating" class="loading">
            <div class="spinner">&nbsp;</div>
          </div>
          <!-- Calulation is over: display result -->
          <div v-else>
            <h4>
              <!-- Correct measure -->
              <div v-if="markerFound && fishSizeAutomatedInMM" class="center">
                <div class="manual-size-input-container">
                  <div class="cm-measure-text">
                    <i class="icon-success success" />
                    Mesure (en cm)<br />
                  </div>
                  <input type="number" v-model="fishSizeManualInCm" min="1" />
                </div>
                <div class="warning-container">
                  <br />
                  <div class="warning">
                    Cette mesure automatique peut être imprécise, vous pouvez la
                    rectifier avant de valider.
                  </div>
                </div>
              </div>
              <div class="error" v-else>
                <i class="icon-error" />
                <span class="measure"> Mesure </span>
                <span v-if="!markerFound">
                  Impossible de détecter le marqueur
                </span>
                <span v-else> Impossible de détecter le poisson </span>
                <br />
                Veuillez vérifier que votre photo suit bien les préconisations
              </div>
            </h4>
            <div class="bottom-actions validate-redo">
              <div class="button button-primary button-main">
                <button
                  v-if="markerFound && fishSizeAutomatedInMM"
                  @click="validate"
                >
                  <i class="icon-fish" />
                  Valider
                </button>
                <button v-else @click="measurementPictureSrc = ''">
                  <i class="icon-redo" />
                  Recommencer
                </button>
              </div>
              <div class="button-minor-left">
                <button
                  @click="measurementPictureSrc = ''"
                  v-if="markerFound && fishSizeAutomatedInMM"
                >
                  <i class="icon-redo" />
                </button>
              </div>
              <div class="button-minor-right">
                <button v-on:click="$emit('close')">Abandon</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PictureTakerService from "@/services/PictureTakerService";
import FisholaOpenCVService from "@/services/opencv/FisholaOpenCVService";
import { DetectedShape } from "@/services/opencv/DetectedShape";
import { OpenCVDetectionConfig } from "@/services/opencv/OpenCVDetectionConfig";
import { Device } from "@capacitor/device";
import MeasurementPictureSlider from "@/components/trip/MeasurementPictureSlider.vue";
import { MeasureAndPic } from "@/services/opencv/MeasureAndPic";
import DocumentationService from "@/services/DocumentationService";
import PicturesService from "@/services/PicturesService";

@Component({
  components: { MeasurementPictureSlider },
})
export default class MeasurementPicturePopup extends Vue {
  @Prop() measurementPicture: string;
  measurementPictureSrc = "";
  markerSourceSRC = "";
  calculating = false;
  openCVLoaded = false;
  errorMessage = "";
  openCVConfig = new OpenCVDetectionConfig();
  fishSizeAutomatedInMM = 0;
  fishSizeManualInCm = 0;
  markerFound = false;
  isMobilePlatform = true;
  markerDocURL = "";
  hasAtLeastAPicWithMarker: Boolean = true;

  created(): void {
    Device.getInfo().then((info) => {
      this.isMobilePlatform =
        info &&
        (info.operatingSystem === "android" || info.operatingSystem === "ios");
    });
  }
  mounted(): void {
    this.markerDocURL = DocumentationService.getMarkerDocumentationUrl();
    this.markerSourceSRC = this.openCVConfig.defaultMarkerSrc;
    this.errorMessage = "";
    FisholaOpenCVService.INSTANCE.loadOpenCVIfNeeded().then(() => {
      this.openCVLoaded = FisholaOpenCVService.INSTANCE.isOpenCVReady();
    });
    PicturesService.hasAtLeastOnePicWithMarker().then(
      (hasAtLeastAPicWithMarker) => {
        this.hasAtLeastAPicWithMarker = hasAtLeastAPicWithMarker;
      }
    );
  }

  @Watch("measurementPicture")
  measurementPictureChanged() {
    if (this.measurementPicture) {
      this.measurementPictureSrc = this.measurementPicture;
    }
  }

  async takePictureAndTryToMeasure(fromCameraIfPossible: boolean) {
    this.measurementPictureSrc = "";
    this.calculating = true;
    this.errorMessage = "";
    this.fishSizeAutomatedInMM = 0;
    this.fishSizeManualInCm = 0;
    this.markerFound = false;
    try {
      // Step 1: take picture
      this.measurementPictureSrc =
        await PictureTakerService.INSTANCE.takePicture(fromCameraIfPossible);
      // Step 2: launch calculation
    } catch (error) {
      console.error("Error while taking measure picture", error);
      this.measurementPictureSrc = "";
      this.calculating = false;
    }
  }

  async sourcePictureLoaded() {
    try {
      console.info(
        "Source picture loaded, launching calculation",
        this.calculating
      );
      const imageElement = document.getElementById("sourcePicture");
      const markerElement = document.getElementById("marker");
      if (this.openCVLoaded && imageElement && markerElement) {
        this.openCVConfig.drawDebugCanvas = false;
        const detectedShapes: Array<DetectedShape> =
          await FisholaOpenCVService.INSTANCE.calculateAndDrawFishSizes(
            imageElement,
            markerElement,
            this.openCVConfig,
            "resultCanvas"
          );

        const markers = detectedShapes.filter(
          (shape: DetectedShape) => shape.isMarker
        ).length;
        this.markerFound = markers === 1;
        const fishes = detectedShapes.filter(
          (shape: DetectedShape) => shape.isFish
        );
        if (fishes.length === 1) {
          this.fishSizeAutomatedInMM = fishes[0].calculatedLenght;
          this.fishSizeManualInCm = Math.round(this.fishSizeAutomatedInMM / 10);
        }
        this.calculating = false;
      } else {
        this.errorMessage =
          "Impossible de déterminer la mesure automatiquement, veuillez réessayer";
      }

      return;
      // Step 2: launch calculation
    } catch (error) {
      this.errorMessage =
        "Impossible de déterminer la mesure automatiquement, veuillez réessayer";
    }
    this.measurementPictureSrc = "";
    this.calculating = false;
  }

  validate() {
    const measureAndPic = new MeasureAndPic(
      this.fishSizeAutomatedInMM,
      this.fishSizeManualInCm * 10,
      this.measurementPictureSrc
    );
    this.$emit("measurementPictureTaken", measureAndPic);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../less/main";

.picture-source-chooser {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);

  @media screen and (min-width: @desktop-min-width) {
    width: calc(100% - @desktop-menu-width);
    margin-left: @desktop-menu-width;
  }

  .title {
    color: @pelorous;
    font-weight: normal;
    font-size: calc(@fontsize-title + 5px);
  }
  .transparent-background {
    height: 18vh;
  }
  .popup-content {
    margin-top: 0px !important;
    height: 82vh;
    overflow-y: auto;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-bottom: 10vh;

    .bottom-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: @solitude;
      padding-left: @margin-large;
      padding-right: @margin-large;
      padding-bottom: @margin-medium;

      @media screen and (min-width: @desktop-min-width) {
        padding-left: @margin-large-desktop;
        padding-right: @margin-large-desktop;
      }

      .picture-source-item {
        padding-bottom: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;

        .pic {
          color: @pale-sky !important;
          padding-right: 10px;
          font-size: x-large;
        }

        &.first-choice {
          padding-bottom: 20px;
        }
      }
    }

    .validate-redo {
      padding-bottom: 20px;
      padding-top: 30px;
    }

    .picture-holder {
      background-color: @gainsboro;
    }
    .picture-display {
      max-height: 30vh;
      max-width: 90vw;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .error {
      color: @cardinal;
    }
    .success {
      color: @lime-green;
    }
    .warning {
      color: @terra-cotta;
      font-size: 12px;
      @media screen and (min-width: @desktop-min-width) {
        font-size: 14px;
      }
      a {
        color: @terra-cotta;
        font-weight: bolder;
      }
    }

    .measure {
      float: left;
      padding-right: 10px;
      color: black;
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border-top: 3px solid @pelorous;
    border-left: 3px solid @pelorous;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 48vh;
    left: calc(50vw - 30px);
    display: block;
  }

  @media screen and (min-width: @desktop-min-width) {
    .spinner {
      left: calc(50vw - 30px - @desktop-menu-width / 2);
    }
  }

  .button-main {
    margin-left: auto;
    margin-right: auto;
    margin-top: -55px;
  }

  .button-minor-left {
    margin-right: auto;
    float: left;
    button {
      color: @pelorous;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0);
      border: 0px solid black;
      font-size: 18px;
    }
  }

  .button-minor-right {
    margin-left: auto;
    float: right;
    button {
      color: @pelorous;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0);
      border: 0px solid black;
      font-size: 16px;
    }
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .warning-container {
    width: 50vw;
    padding-left: 30px;
    max-width: 400px;
  }

  .cm-measure-text {
    display: flex;
    justify-content: start;
    i {
      padding-right: 8px;
    }
  }

  .manual-size-input-container {
    width: 36vw;
    max-width: 160px;
    font-size: 14px;

    @media screen and (max-width: 365px) {
      width: 50vw;
    }
    input {
      padding-left: @margin-small;
      padding-right: @margin-small;
      margin-top: @vertical-margin-xx-small;
      width: 100%;
      height: 38px;
      border-radius: 4px;

      background: transparent;
      border: 1px solid @pale-sky;

      color: @gunmetal;
      font-size: @fontsize-form-input;
      font-family: "Open Sans", sans-serif;
      text-align: right;
      font-size: 14px;
    }
  }
}
</style>
